<template>
    <div class="main site-content site-mover" >
        <nav class="account-navigation  navigation-suggestion">
            <div class="customMyAccountMenu">
                <ul class="legend">
                    <li class="overview" v-for="(item,index) in navListArr" :key="index"
                        :class="selected == index ? 'selected' : ''" @click="currentFn(index)">
                        <router-link :to="{path:item.paths,query:{active:index}}">{{ item.title }}</router-link>
                    </li>
                    <li class="overview" @click="logout">
                        <router-link :to="{path:'/login'}"></router-link>
                    </li>
                </ul>
            </div>
        </nav>
        <div class="account-overview account-section">
            <router-view></router-view>
        </div>
    </div>
</template>
<script>
export default {
    name: "myAccount",
    components: {},
    data() {
        return {
            fix: null,
            selected: 0,
            navListArr: [
                {id: 1, title: "Overview", paths: "overView"},
                {id: 2, title: "My Profile", paths: "profile"},
                {id: 3, title: "My Orders", paths: "orders"},
                {id: 4, title: "My Addresses", paths: "myAddress"}
            ]
        };
    },
    // mounted() {
    //     this.isFixed();
    //     window.addEventListener("scroll", this.isFixed);
    // },
    methods: {
        // isFixed() {
        //     console.log(document.body.scrollTop)
        //     if ((document.documentElement.scrollTop || document.body.scrollTop) === 0){
        //         this.fix = false
        //     }else {
        //         this.fix = true
        //     }
        // },
        currentFn(index) {
            this.selected = index;
        },
        logout() {
            this.$parent.clearUserInfo();
            sessionStorage.removeItem("accessToken");
        }
    },
    watch: {
        $route: {
            immediate: true,
            handler(old) {
                this.selected = old.query.active;
                if (old.path == "/overView") {
                    this.selected = 0;
                }
            }
        }
    }
};
</script>
<style lang="scss" scoped>
a {
    text-decoration: none;
    color: inherit;
}

.site-content {
    .navigation-suggestion {
        margin: 1rem 0 2rem;
        padding-left: .625rem;
        padding-right: .625rem;

        ul {
            overflow: auto;
            padding: 1rem 0;
            text-align: center;
            white-space: nowrap;
            width: 100%;

            li {
                display: inline-block;
                margin: 0 .625rem;
            }

            .selected {
                border-bottom: 1px solid currentColor;
            }
        }
    }
}

@media (min-width: 1280px) {
    .smartphone-back-button {
        display: none;
    }
    .site-content {
        //margin-bottom: 5.625rem;
        //min-height: calc(100vh - 6.875rem);
        .navigation-suggestion {
            padding: 0;
            display: block;
            margin: 1rem 0 2rem;
        }
    }
}

@media (max-width: 1280px) {

    nav {
        display: none;
    }
}
</style>
